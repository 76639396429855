@media only screen and (min-width:1024px) and (max-width: 1366px) {
	// .opinion-content {
	// 	h1{
	// 		width: 300px;   
	// 		font-size: 26px !important;  
	// 	}
	// }  
	.main_video_content{
    height: 420px; 
    .main_video_category{
        img{ 
            height: 100%; 
        }

    } 
}  
	
	.video { 
		.container-right-side { 
			width: calc(75% - 10px);
			padding-left: 0px; 
			.content { 
				// width: 795px;
				.main-title h1 {  
					font-size: 40px !important;
				}
			} 
		} 
		&_wrapper {
			// height: 470px; 
		}
		&_blic {
			// height: 460px;
		} 
	}
	.video_description {
		// height: 360px;
	} 
	.video_blic { 
		.container-left-side {			
			width: calc(25% + 10px); 
		}  
	}
	.article_header {  
		// margin-left: -30%; 
	} 
	.main_video {
		width: 100%;
		// height: 380px;
		img { 
			// height: 380px;
		}
	}
	.title_holder {
		h3 {
			height: 35px;
			line-height: 35px;
			padding: 0 5px;  
			font-size: 14px;
			// margin-right: 20px;  
	     }  
	}   
   .video_description {
   		width: 100%;  
   		// height: 572px; 
   		li { 
   			// width: calc(100% + 15px);  
			p {  
				font-size: 15px;  				
			} 
   		} 
	}
	.main_video_category {
	    width: 960px;  
	    // height: 400px;
	    img {
		    width: 960px;
		    height: 400px;
	    }
    }
   
	  
	.cat_container { 
		max-width: calc(90% - 300px);
	}
	.flexslider .left-content p {
		font-size: 34px;
	}
	.flexslider {
		height: 380px;
	}
	.four-article .box_style .thumb-holder,
	.box_style .thumb-holder{
		height: 160px;
	}
	#tjera .others_slider .others-content-item h1,
	.side_content h1,
	.bottomNews ul li p,
	.photo_video_wrap .photo_wrap .photo_content h1,
	.opinion-fullwidth .opinion_wrap ul li .opinion-content h1,
	.latest-wrap .latestNewsExcerpt h1,
	.box_style .article_box_content h1{
		font-size: 18px;
	}
	.opinion-fullwidth .opinion_wrap ul li .opinion-content p,
	.latest-wrap .latestNewsContent p{
		font-size: 14px;
	}
	.cult_wrap .side_big_article,
	#most_funny .side_big_article{
		width: 64.4%;
	}
	#most_funny .side_big_article .content_big_article h1{
		font-size: 34px;
	}
	.content_slider .sport-slider .sport-slider-content h1{
		font-size: 36px;
	}
	.cult_wrap .side_big_article .content_big_article h1{
		font-size: 40px;
	}
	#single-content h1{
		font-size: 40px;
	}
	
	#single-content{
		width: calc(100% - 300px); 
		// margin-left: -30%;
	}
	.cult_wrap .side_big_article .content_big_article,
	#most_funny .side_big_article .content_big_article{
		height: 250px;
	}
	.cult_wrap .side_big_article .thumb_big_article,
	#most_funny .side_big_article .thumb_big_article{
		height: 300px;
	}
	.content{  
		// width: calc(100% - 30px);
		width: 960px; 
	}
	.opinion-fullwidth .opinion_wrap ul{
		padding: 40px 30px;
	}
	.main-banner{
		height: 360px;
	}
	.latest-wrap .thumbLeft{
		height: 180px;
	}
	.main-title h1{
		font-size: 40px;
	}
	#tjera .main-title h1 span.color-txt,
	#tjera .geek h1 span.color-txt,
	#tjera .cult h1 span.color-txt,
	#tjera .fit h1 span.color-txt{
		font-size: 40px;
	}
	.side_content,
	.side_img{
	    height: 200px;
	}
	.opinion-fullwidth h2.opinion-title{
		font-size: 40px;
	}
	.opinion-fullwidth h2.opinion-title:after{
		background-size: 80%;
		margin-top: -1px;
	}
	.single-full-content .single-thumb{
		// height: 360px;
	} 
	
	body.nav_toggled .left-side-header{
		width: calc(25% - 30px);
	}
	#sidebar nav{
		margin-top: 130px;
	}
	.container-left-side{
		width: 22%;
	}
	.container-right-side{
		width: 78%;
	} 
	.flexslider .left-content{
		width: 25%;
	}
	.top_menu{
		width: calc(25% - 30px);
	}
	.flexslider .slider_img{
		width: 75%;
	}
	.flexslider{
		height:450px;
	} 
}
@media only screen and (min-width:1600px) {
	// .main_video{  
	// 	width: 1100px;  
	// }    
	.aboutus_banner_wrapper { 
	    background-size: contain;
	    background-position: 0 0;
	    background-repeat: no-repeat;
	    background-attachment: fixed;
	}
	#single-content{
		width: 800px;
	}
	.right-rek{
		margin-right: -160px;
	}
} 

@media only screen and (min-width:1900px) {
	#single-content{
		width: 960px;
	}
	.right-rek{
		margin-right: -400px;
	}
	.home .flexslider{
		height: 600px;
	}
}
@media only screen and (min-width:1400px) { 
   .blic_video{
		.videos{
		.title_holder{
			padding-top: 20px;
		   }  
		}
	}  	       
	.aboutus_banner_logo {
		left: -110px;
		position: absolute;
		&.banner_logo {
		    left: auto;
			position: relative;
		}
	}
	.left_diff {
		padding-left: 20px;
	}
	.top_bar_section {
		.top_bar_content {
			width: 100%;
		}
	}
	.top_menu{
	    width: calc(25% - 30px); 
	}
	.rek-content{
		width: 75%;
	}
	.zgjedhjet_slider {
		.article_box_content {
			h1 {
				font-size: 19px !important;
			}
		}
	    .thumb-holder {
	        img {
	            height: 170px;
	        }
	    }
	}
	.partite_sidebar {
	    float: right;
	    margin-right: 50px;
	}
	.flexslider .left-content{
		width: 25%;
	}
	.flexslider .slider_img{
		width: 75%;
	}
	.container-left-side{
		width: 25%;
	}  
	.container-right-side{ 
		width: 75%;
	}
	.single-full-content{
		width: 75%;
	}
	.flexslider{	
		height: 510px;
	}
	// .video_blic .container-left-side{
	//     width: calc(30% + 10px);
	// }
	// .video .container-right-side{
 //     	width: calc(70% - 10px);
	// }
	.video .container-right-side .content{
      width: 95%;
	}
	// .video_blic .container-left-side .tabs_content .video_holder{
	// 	max-width: 90%;
	// } 
	 .video{
 	.container-videot-fundit{
 		.video-right-side{
 		   width: 75%;
           float: right;
           .content{
           	width: 960px; 
           }
 		}
 	}
 } 
}
 
@media only screen and (min-width:1024px) and (max-width: 1350px) {
	.top_menu{	
		.current-date{
			display: none;
		} 
	}  
	#sidebar{
		padding-left: 40px;
	}
	.zgjedhjet_slider_holder {
		padding-top: 20px;
	}
	.small_screen_element {
		display: block;
	}
	.flex-direction-nav {
		a {
		    top: -35px;
		    bottom: auto;
		    left: auto !important;
		}
		.flex-prev {
		    right: 50px;
		}
		.flex-next {
			right: 0px;
		}
	}

	.main_nav ul li{
		margin-right: 10px;
	}
	.main_nav ul li a{
		font-size: 15px;
	}
	.form-area{
		margin-right: 10px;
	}
	
	.footerWrap{
		width: calc(100% - 60px);
	}
	.article-posts{
		float: left;
		width: 68%;
		padding-left: 15px;
	}
	.content_slider .sport-slider .sport-slider-content h1{
		max-width: 724px; 
	}
	.bottomNews ul li{
		margin-top: 30px;
	}

}
@media screen and (min-width:1280px) and (max-width: 1400px){
	.other-items{
		width: 100%;
	} 
	
	 }
@media screen and (min-width:1280px) and (max-width: 1500px){
	.video_blic{ 
	  .videos{
		.title_holder{  
			// padding-top: 20px;
			h3{ 
				font-size: 16px;      
			 }  
		   }   
		}
	} 
	.video_category{
		.video_blic{ 	  	 
		   .title_holder{ 
			padding-top: 20px;
			h3{ 
				// font-size: 16px;    
			} 
		   } 	 
	   }   
	}
	.latest-wrap .thumbLeft{
		height: 200px;
	}
	.box_style .thumb-holder, .four-article .box_style .thumb-holder{
		height: 180px;
	}
	.single-full-content .single-thumb{
		// height: 540px; 
	}   
	.cult_wrap .side_big_article,
	#most_funny .side_big_article{
		width: 65%;
	} 
}


@media only screen and (min-width:1024px) and (max-width: 1170px) {
	.container{
		max-width: 1170px !important;
	}
	.main_nav ul li a{
		font-size: 14px;
	}
	.form-holder{
		right: 54px;
		width: 68%;
	}
	.form-area{
		margin-right: 0;
		right:10px;
	}
	body.nav_toggled .left-side-header{
		width: calc(28% - 30px);
	}
	.cult_wrap .side_big_article,
	#most_funny .side_big_article{
		width: 63.9%;
	}
	
}

@media only screen and (min-width:960px) and (max-width: 1280px) { 
	.container-left-side {
		display: none;
	} 
	.video_blic{
	 .container-left-side {
	 	display: block; 
	    } 
	 }
	 .category-video{
    	.video{
          .container-right-side{
      	     width: 100%;
         }
       }
    }   
	 .video{
      .container-right-side{
        padding-left: 0px;
        width: calc(75% - 10px); 
      .content{
      .main-title 
        h1{
         font-size: 51px !important;
           }
        } 
    }  

   } 
  .video_blic { 
	.container-left-side{
	          width: calc(25% + 10px); 
	         }    
	      }    
	.container-right-side{
		width: 100%; 
	}
	.content{
		display: block;
		margin:0 auto;
		float: none;
		width: 960px;
	}
	 
	.flexslider .left-content p{
		font-size: 30px;
	}
	.top_menu{
		position: absolute;
	}
	.box_style .thumb-holder{
		height: 220px;
	}
	.latest-wrap .thumbLeft{
		height: 220px;
	}
	.main-banner{
		height: 400px;
	}
	.box_style .article_box_content h1,
	.latest-wrap .latestNewsExcerpt h1{
		font-size: 20px;
	}
	.flexslider .left-content{
		width: 30%;
	}
	.flexslider .slider_img{
		width: 70%;
	}
	.top_menu{
	    width: calc(25% - 30px);
	}
	.cat_container{
		width: 960px;
		max-width: 960px; 
	}
	#single-content{
		width: 70%;
	}
	.other-items{ 
		width: 100%;
	}
	.left-rek-holder {
		display: none;
	}
	#left_element{
		// display: none;
		width: 100px;
		margin-left: -110px;

		.left-rek-holder {
			display: none;
		}
		.tags_area{
			display: none;
		}
	}
	.big_single_wrap{
		// margin-left: -30%;
		// width: 900px;
		.single-content-wrap{
			width: 100%;
		} 
		#single-content{
			width: 80%;
		}
	}
	
}

@media only screen and (min-width:960px) and (max-width: 1200px) { 
	.right-rek{
	 	display: none;
	} }
@media only screen and (min-width:1280px) and (max-width: 1370px) {
	.box_style .thumb-holder, .four-article .box_style .thumb-holder{
		height: 220px;
	}
}
@media only screen and (min-width:1280px) and (max-width: 1440px) {
	.latest-wrap .thumbLeft,
	.box_style .thumb-holder, .four-article .box_style .thumb-holder{
		height: 220px;
	}	

}

@media only screen and (min-width:960px) and (max-width: 1000px) {
	.content{
		padding-left:15px;
		padding-right:15px;
		width: 100%;
	}
	
}
@media only screen and (min-width:960px) and (max-width: 1080px) {
	.opinion-fullwidth .opinion_wrap ul li .opinion-content p{
		display: none;
	}
	.opinion-fullwidth .opinion_wrap ul li .opinion-content h1{
		font-size: 18px;
		margin-top: 10px;
	}
	.opinion-fullwidth .opinion_wrap ul{
		padding: 30px;
	}
	.opinion-fullwidth h2.opinion-title{
		padding-left:10px;
	}
	.top_menu .current-date{
		display: none;
	}
	.cat_container{
		width: 960px;
		max-width: 960px; 
		padding: 0 30px;
	}
	#single-content{
		width: 550px;
	}
	.big_single_wrap{
		// width: 820px; 
	} 
	.video_blic {
		.container-left-side {
		 	display: block; 
		}
	}
}


 

@media only screen and (min-width: 1020px) and (max-width: 1520px){
	.video_blic{
		height: 540px;
	} 
	.video_wrapper{
		height: 540px; 
	}
	.videos { 
		.title_holder{
			padding-top: 20px; 
		 }  
	}
	.video{ 
		.main_video{
			height: 468px; 
			margin-top: 20px; 
			img{   
				height: 100%; 
			}
			.video_content{
				margin-bottom: -20px;
				p{
					font-size: 40px; 
				} 
			}
		}
	}
	.video_holder{
		.list-overlay{
			margin-top: -213px; 
		}
	} 
	.single_content_holder {
		.single-full-content {
			.single-thumb { 
				width: 100%; 
				margin-left: 0;    
			}   
		}
	}
	.single {
		.video_description {
			li {
				p {
					width: 52%;
					font-size: 12px
				}
			}
		}
		.title_holder {    
			padding-top: 15px;
			h3 {
				font-size: 12px;
			} 
		}
	}
	.title_holder {    
	    padding-top: 45px;
	    margin-left: 10px;
	    margin-bottom: 10px;
	    h3 { 
	    	height: 30px;
	    	font-size: 13px; 
	    	line-height: 30px;   
	    }
	}
	
	.video_description {  
		li { 
			a {
				padding: 10px;
				padding-bottom: 0;
				.play { 
					width: 30px; 
					height: 30px; 
				}
				.image_holder {
					width: 100px;
					height: 60px;
					margin-right: 10px;
					img { 
						width: 100px;
						height: 60px;
				    }
				} 
			}  
			p {
				width: 55%;
    			font-size: 15px;  
			}
			.video_inner_wrapper {
				// padding-bottom: 10px;
				padding-top: 10px;
			}  
			.image_holder {
				margin-right: 10px; 
				height: 80px; 
			}  
		} 
 
	}  

	.single-full-content { 
		width: 75%;
		.single-thumb {
			height: 460px;  
			iframe {
				height: 460px !important;
			} 
		} 		
	}   
	.video_category{
	.article_header { 
		width: 90%; 
		float: left; 
		margin-left: 0; 
		 }  	   
	}  
	.video_blic{ 
		&.single_tabs {
			height: 460px;  
		}
		.container-left-side { 
		 	display: block !important; 
		}
	} 
	.article_header { 
		width: 90%;   
		float: left; 
		margin-left: 0; 
		// margin-left: -15%;
	} 
}   

@media only screen and (min-width: 1400px) and (max-width: 1520px){
	.video_blic{ 
		&.single_tabs {
			height: 500px;  
		}
	}

	.single-full-content { 
		width: 75%;
		.single-thumb {
			height: 500px;  
			iframe {
				height: 500px !important;
			} 
		} 		
	}	
}  

@media only screen and (min-width:770px) and (max-width: 1020px){
 .single_content_holder{
	.single-full-content{ 
		.article_header{  
			margin-left: -30%; 
			}    
		}  
	}  
}  

@media only screen and (min-width:960px) and (max-width: 1024px) {
	.video_blic {
		.container-left-side {
			width: 30%;
			.title_holder {
				h3 {
					font-size: 14px;
				}
			}
			.video_description {
				p {
					font-size: 14px;
				}
			}

		}
		.container-right-side {
			width: 68%;
			margin-right: 15px;
			.content {
				width: auto;
			}
		}
	}
 .opinion_wrap{
	.opinion-content {
		p {
			width: 250px !important;
		  }
		h1 {
			width: 250px !important;
			font-size: 25px;
		} 
		}
	}
	.single-full-content {
		width: 75%;
		.single_thumb{
			height: 580px; 
		} 
	}

	.video_blic{
		.single_tabs{
			height: 580px;  
		}
		.title_holder{
			h3{
				font-size: 12px;  
			}
		}
		.video_description{
			.image_holder{
				width: 100px;
				height: 60px;
			} 
			p{
				font-size: 14px; 
				width: 45%; 
			}
		}
	}
	.category-video{
    	.video{
          .container-right-side{
      	     width: 100%;
         }
       }
    } 

}

@media only screen and (min-width:1590px) and (max-width: 1680px) {
	.single{
	 .video_category{  
		.video_blic{			
				height: 575px; 	    
	   } 
	   .single-full-content{
	   	  .single-thumb{
	   	  	height: 575px; 
	   	  	iframe{
	   	  		height: 575px !important; 
	   	  	} 
	   	  } 	   	
	   }	
     } 
  } 
}  

@media only screen and (min-width: 1300px) and (max-width: 1366px){
	.single{
	.video_category{ 
		.video_blic{			
				height: 575px; 	    
	   } 
	   .single-full-content{
	   	  .single-thumb{
	   	  	height: 575px; 
	   	  	iframe{
	   	  		height: 575px !important; 
	   	  	} 
	   	  } 	   	
	   }	
     } 
  }
   .video{
 	.container-videot-fundit{
 		.video-right-side{
 		   width: 78%;
           float: right;
           .content{
           	width: 960px; 
           } 
 		}
 	}
 }  
}

 .category-video{
    .container-right-side{
        padding-left: 0; 
    }
}  


