/* MEDIAQUERIES */

$mobile: "only screen and (max-width : 767px)";
$tablet: "only screen and (min-width : 768px) and (max-width: 990px)";
$tablet-big: "only screen and (min-width : 991px) and (max-width: 1024px)";
$desktop: "only screen and (min-width : 1200px) ";
@mixin mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) and (max-width: 990px) {
        @content;
    }
}

@mixin tablet-big {
    @media only screen and (min-width: 991px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
%centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%fullBlock {
    float: left;
    width: 100%;
}

%imgCropped {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
%absoluteFull {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* colors */
%orange{
    color: #ff4202; 
}

/// Rem mixin to calculate rem based spacing from the base pixel of the html document 
/// Which can be set inside the mixin itself
///@author Lend Kelmendi
///@param {CSS Property} $property - any css property
///@param {Value} $values - property value in pixels ONLY
///@output Calculated style from px to rem together with px fallback
///@example
/// h1 {
///   @include rem('font-size',16px); 
/// }
/// //It outputs this: h1{font-size,16px; font-size:1rem;}
@mixin rem($property, $values, $important:false) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: 16px; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: (); // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value==0 or $value==0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0); 
      $rem-values: join($rem-values, 0);
    }
    @else if type-of($value)==number and not unitless($value) and (unit($value)==px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: $value / $base-font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    }
    @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $base-font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
    @if $important==true {
      #{$property}: $px-values !important;
      #{$property}: $rem-values !important;
    }
  } // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
} 