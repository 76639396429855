//GRID GLOBALS Default 12 and 30px
$grid-columns:12;
$gutter:30px;
//Transitions
$transition1: all .3s cubic-bezier(0.3, 0.0, 0.2, 1);
$transition2: all .45s cubic-bezier(0.45, 0.0, 0.2, 1);
$transition3: all .8s cubic-bezier(0.8, 0.0, 0.2, 1);
// Custom Transitions
$default-transition: all .2s ease-in-out;
//Colors
$mainColor: #FF4202;
$silverColor: #B3B3B3;
$lightenBlack: #333333;
$placeholderColor: #808080;