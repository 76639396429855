#footer{
	background:#212121;
	z-index: 999;
	position: relative;
	margin-top: 60px;
	#back-to-top{
		position: absolute;
		right: 20px;
		top: -25px;
		background:url('../images/up-arrow.png') #ef4823 center no-repeat;
		background-size:20px;
		height:  50px;
		width: 50px;
		border-radius:50%;
	}
}
.footerWrap{
	padding:40px 0;
}
.footer-leftInfo{
	float:left;
	width:300px;
	.footer-logo-social{
		float: left;
		width: 100px;
	}
	.footer-logo{
		float: left;
		width: 100%;
	}
	.social-network{
		float: left;
		width: 100%;
		margin-top: 16px;
		li{
			float: left; 
			width: 42px;
			height: 42px;
			margin-right: 15px;
			margin-bottom: 15px;
			transition: $transition1;
			&:nth-child(even){
				margin-right: 0;
			}
			a{
				float: left;
				width: 100%;
				height: 100%;

			}
			&:hover{
			 background-color: #d9dbde;
			}

		}
		li.fb{
			 background: url('../images/fb-icon.png') no-repeat center;
			 background-color: #939598;

		}
		li.twitter{
			 background: url('../images/twitter-icon.png') no-repeat center;
			 background-color: #939598;
		}
		li.insta{
			 background: url('../images/insta-icon.png') no-repeat 48%;
			 background-color: #939598;
		}
		li.youtube{
			 background: url('../images/youtube-icon.png') no-repeat center;
			 background-color: #939598;
		}
		li:hover{
			 background-color: #d9dbde;
		}

	}
	.blic-info{
		float: left;
		padding-left:20px;
		width: 200px;
		p{
			font-size: 12px;
			color: #939598; 
			line-height: 1.3em;
			font-weight: 400; 
			margin-bottom: 10px; 
		} 
	}
}
.article-posts{
	float: left; 
	width: 870px;
	padding-left: 85px;
	li{
		width: 33.3%;
		float: left;
		margin-bottom: 10px; 
		padding: 0 30px;
		&:last-child{
			float: right;
			padding-right:0;
			padding-left:30px;
		}
		
		a{
			float: left;
			width:100%;
			margin-bottom: 10px;
			transition: $transition1;
			&:hover{
				p{
					color: #fff;
				}
				span{
					color: #fff;
				}
			}
		}
		p{
			font-size: 12px;
			color: #939598; 
			line-height: 1.3em;
			font-weight: 400;
			float: left;
			transition: $transition1;
			
		}
		span{
			font-weight: 400;
			font-size: 12px;
			float: right;
			color: #939598; 
			transition: $transition1;
		}
	}
}
.diff_menu {
	&_footer {
		ul {
			float: left;
			width: 100%;
			li {
				float: left;
				width: 100%;
				a {
    				float: left;
    				width: 100%;
				    color: #939598;
				    font-size: 12px;
				    font-weight: 400;
				    line-height: 1.3em;
				    transition: $transition1;
				    &:hover {
				    	color: white;
				    	transition: $transition1;
				    }
				}
			}
		}
	}
}
.bottom_nav{
	float:left;
	width:100%;
	padding:26px 0 0;
	border-top:1px solid #939598;
	margin-top:42px;
	ul{
		li{
			float: left;
			margin-right: 25px;
			a{
				font-weight: 700;
				font-size:14px;
				color: #939598;
				text-transform: uppercase; 
			}
		}
	}
}