body {
    font-family: 'Roboto', sans-serif;
} 
.maindiv {
    float: left;
    width: 100%;
}
.video_play_button {
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    background: url(../images/play_small.png) no-repeat 50%;
    background-color: hsla(0,0%,100%,.4);
}
.flexslider {
    float: left;
    z-index: 91;
    height: 450px;
    margin: 0 30px;
    overflow: hidden;
    // margin-left: -30px;
    border-radius: 5px;
    width: calc(100% - 60px);
    box-shadow: 0px 0px 36px 4px #e5e5e5;
    .flex-viewport {
        width: 100%;
        height: 100%; 
        overflow: hidden; 
    }
    ul.slides {
        height: 100%;
        transform-style: flat !important;
        li {  
            height: 100%;
            display: none; 
            &:first-child{  
                display: block; 
            }
        } 
    }
    .left-content {
        float: left;
        width: 30%;
        background: #e5e5e5; 
        height: 100%;
        padding: 35px;
        padding-right: 15px;
        position: relative;
        border-top-left-radius: 4px; 
        border-bottom-left-radius: 4px;
        span.cat-post {
            color: #ff4202;
            font-size: 16px;
            font-weight: 900;
            padding-left: 22px;
            text-transform: uppercase;
            background: url(../images/cat-icon.png) no-repeat left 2px;
            background-size: 16px;
        }
        p {
            font-size: 40px;
            font-weight: 900;
            color: #333;
            line-height: 1.1em;
            margin-top: 30px;
            word-wrap: break-word;
        }
    }
    .slider_img {
        float: left;
        width: 70%;
        height: 100%;
        position:relative; 
        img{  
            @extend %imgCropped;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    .flex-direction-nav {
        display: none;
    }
    .flex-control-paging {
        position: absolute;
        left: 30px;
        bottom: 30px;
        text-align: left;
        a.flex-active {
            background:#ff4202;
        }
        a{
            background:#212121;
        }
    }
}


.content {
    float: right;
    width: 82%;
}
.clear:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; 
}
body.logged-in{
    #sidebar{
        nav{
            margin-top: 160px;
        }
    }
} 

#sidebar {
    width: 210px;
    float: right;
    nav.stickydiv {
        position: fixed;
        top: 20px;
        z-index: 100;
    }
    nav{
        margin-top: 140px;
    }
    nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    nav li {
        padding: 5px 10px;
    }

    nav li a {
        color: #000;
        font-weight: 700;
        line-height: 25px;
        text-transform: uppercase; 
    }
    nav li a:hover {
        color: #ff4202;
        text-decoration: none;
        padding-left:10px; 
        transition: $transition1;
    }
    a {
        text-decoration: none;
        transition: $transition1;
    }

    nav li a:hover,
    .active {
        color: #ff4202; 
        text-decoration: none;
        padding-left:10px;
        &:after{
            content: "";
            float: left;
            width: 10px;
            height: 10px;
            margin-top: 7px;
            background-color: #ff4202; 
            transition: $transition1;  
        }  
    }
}

.main-title {
    float: left;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 14px;
    border-bottom: 1px solid #939598;
    a{
        float: left;
        width: 100%;
    }
    h1 {
        @extend %orange;
        font-family: 'Archivo Black', sans-serif;
        font-size: 50px;
        font-weight: normal;
        text-transform: uppercase;
    }
}
 
.main-banner {
    float: left;
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    &:hover{
        img{
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            opacity: 0.9;
        } 
        .inner-content h1{
            color: #ff4202;
        }
        .inner-content span.date-time{
            color: #ff4202;
        }
    }
    img {
        @extend %imgCropped;
        border-radius: 4px;
        transition: $transition3;
        opacity: 1;
    }
    .inner-content {
        border-radius: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        background: -webkit-linear-gradient(top, transparent 0%, transparent 0%, #000 100%, #000 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0%, transparent), color-stop(90%, #000), to(#000));
        background: linear-gradient(to bottom, transparent 0%, transparent 0%, #000 100%, #000 100%);
        h1 {
            font-size: 34px;
            color: #fff;
            font-weight: 900;
            margin-top: 10px;
            transition: $transition3;
        }
        span.date-time {
            font-size: 12px;
            color: #fff;
            font-weight: normal;
               transition: $transition3;
        }
    }
}

.article_box {
    float: left;
    width: 50%;
    padding: 0 15px;
    margin-top: 30px;
    transition: $transition1;
    &:hover span.hover_line {
        background: #ff4202;
        width: 60px;
    }
}
 .play_button{
    @extend %centered;
    background: url('../images/video_icon.png') no-repeat center;
    float: left;
    width: 93px;
    height: 93px;
    transition: $transition1;
}

.box_style {
    transition: $transition1;
    &:hover .article_box_content h1 {
        @extend %orange;
    }
    &:hover .play_button{
        background: url(../images/video_icon_hover.png) no-repeat 50%;
    }
    .thumb-holder {
        height: 220px;
        float: left;
        width: 100%;
        overflow: hidden;
        transition: $transition1;
        position:relative;
             img {
            @extend %imgCropped;
            border-radius: 4px;
            transition: $transition1;
            opacity: 1;
        }
           
    }
       
    .article_box_content {
        float: left;
        width: 100%;
        transition: $transition1;
         &:hover .play_button{
            background: url(../images/video_icon_hover.png) no-repeat 50%;
        }
        span {
            font-size: 16px;
            color: #939598;
            font-weight: normal;
            display: block;
            margin-top: 18px;
        }
        h1 {
            font-size: 24px;
            font-weight: 900;
            color: #212121;
            margin-top: 14px;
            line-height: 1.2em;
            transition: $transition1;
        }
    }
    span.hover_line {
        float: left;
        margin-bottom: 20px;
        width: 10px;
        height: 10px;
        background: #212121;
        transition: $transition1;
    }
     &:hover {  
         img{
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            opacity: 0.9;
            } 
         }
}

.pdr15 {
    padding-right: 15px;
}

.pdl15 {
    padding-left: 15px;
}

.boxes {
    float: left;
    width: 100%;
    li {
        float: left;
        width: 33.3%;
        padding: 0 15px;
    }
} 
#kryesoret{
    .article_box{
        margin-bottom: 30px;  
    }
}
#most_popular{
    .photo_video_wrap{
        margin-top: 30px;

    }
}

.newsList {
    float: left;
    width: 100%;
    a.loadmore {
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        color: #212121;
        padding: 23px;
        background: #e5e5e5;
        width: 100%;
        float: left;
        border-radius: 4px;
        margin-top: 46px;
        transition: $transition1;
        &:hover{
            color: #ff4202;
        }
    }
    .box_style {
        margin-bottom: 15px;
    }
}

.latest-wrap {
    float: left;
    width: 100%;
    border-bottom: 1px solid #939598;
    margin-bottom: 30px;
    padding-bottom: 30px;
    a{
        float: left;
        width: 100%;
        transition: $transition1;
        &:hover{
            h1 {
                @extend %orange;
            }
            .date-time{
                @extend %orange;
            }
             img{
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            
            opacity: 0.9;
            }
            .play_button{
                background: url(../images/video_icon_hover.png) no-repeat 50%;
            } 

        }

    }
    .thumbLeft {
        float: left;
        width: 50%;
        height: 220px;
        transition: $transition1;
        overflow: hidden;
        position:relative;
            img {
                @extend %imgCropped;
                border-radius: 4px;
                transition: $transition1;
               opacity: 1;
            }
    }
    .latestNewsExcerpt {
        float: left;
        width: 25%;
        padding-left: 30px;
        transition: $transition1;
        .date-time {
            font-size: 16px;
            color: #939598;
            font-weight: 400;
             transition: $transition1;
        }
        h1 {
            font-size: 24px;
            font-weight: 900;
            color: #212121;
            margin-top: 15px;
            line-height: 1.1em;
            transition: $transition1;
        }
    }
    .latestNewsContent {
        float: left;
        width: 25%;
        padding-left: 30px;
        p {
            color: #000;
            font-size: 18px;
            line-height: 1.4em;
            font-family: 'Merriweather', serif;
        }
    }
}

#latestNews {
    .newsList {
        margin-top: 0px;
    }
}

#opinion,
#latestNews,
#kryesoret {
    float: left;
    width: 100%;
}

.opinion-fullwidth {
    float: left;
    width: 100%;
    z-index: 631; 
    background: white;
    padding-top: 62px;
    position: relative;
    .opinion_wrap {
        float: left;
        width: 100%;
        background: #232323;         
        ul {
            float: left; 
            width: 100%;
            // padding: 80px 30px;
            padding: 0 !important; 
            li {
              height: 360px;                  
              padding-top: 20px;
              padding-bottom: 20px;
              width: 33.3333% !important; 
                 &:hover{ 
                    .rectangle_hover{
                        display: block;  
                    }
                 }          
                    &:hover {
                    background-color: #4fe0c4; 
                    .circle-thumb img {
                        // border: 2px solid #ff4202;
                    } 
                    .opinion-content h1 {
                        color: #232323;
                        display: none;  
                    }
                    .opinion-content p {
                        font-size: 18px !important;
                        font-family: 'Roboto Slab', sans-serif !important;
                        font-weight: 400; 
                        width: auto; 
                        color: #232323;
                        display: block;  
                        margin-bottom: 65px; 
                        margin-top: 30px; 
                        line-height: 1.01em !important; 

                    }
                    .opinion-content h5{
                        font-family: 'Roboto', sans-serif;  
                        font-size: 16px; 
                        font-weight: 500;
                        color: #ffffff;
                        background-color: #232323;  
                    }
                }
                float: left;
                width: 33.3%;
                padding-right: 50px;
                transition: $transition1;
                .circle-thumb {
                    width: 100%;                     
                    float: left;
                    margin-top: 20px;  
 
                    img { 
                        @extend %imgCropped;
                        // border: 2px solid #212121;
                        // border-radius: 50%;
                        display: inline-block; 
                        width: 60px;  
                        height: 60px;    
                        transition: $transition1;
                        margin-left: 60px; 
                        margin-bottom: 20px;  
                        object-fit:contain;   
                    } 
                }
                .opinion-content {
                    float: left; 
                    // width: calc(100% - 120px);
                    width: 350px;  
                    padding-left: 60px; 
                    p{
                        display: none; 
                    }
                    h5 { 
                        color: #232323; 
                        font-family: 'Roboto', sans-serif;  
                        font-size: 16px; 
                        font-weight: 500; 
                        background-color: #4fe0c4; 
                        width: 150px;
                        height: 40px;
                        line-height: 40px; 
                        text-align: center;
                        
                    }
                    .opinion-txt{
                    h1 {  
                    font-size: 28px;     
                    font-weight: 700; 
                    font-family: 'Roboto', sans-serif;
                    color: #ffffff;                       
                    margin-top: 30px;                       
                    transition: $transition1;
                    width: auto;   
                      }  
                    }
                    p {
                        font-family: 'Merriweather', serif;
                        font-size: 18px;
                        color: #999999;
                        margin-top: 24px;
                        line-height: 1.3em;
                        transition: $transition1;
                    } 
                } 
            }
        }
    }
    h2.opinion-title{
        font-size: 57px !important;
        color: #212121; 
        padding-left: 42px;
        line-height: 0.9em; 
        margin-bottom: -5px;
        text-transform: uppercase;
        font-family: 'Archivo Black', sans-serif;
        background:#fff;
        &:after{
            content: ""; 
            float: left;
            width: 48px;
            height: 48px; 
            margin-top: 6px;
            margin-right: 10px;
            background-size: 100% !important; 
            background: url('../images/opinione-icon.png') no-repeat center;
        } 
    }
}

.photo_video_wrap {
    float: left;
    width: 100%;
    .photo_wrap {
        float: left;
        width: 50%;
        padding: 0 15px;
        position: relative;
        height: 264px;
        transition: $transition1;
        &:hover{
            .photo_icon{
                background: url('../images/player-icon-hover.png') no-repeat center;           
            }
            .photo_content {
                h1{
                    color: #ff4202;
                }    
            }


            
        }
        img {
            @extend %imgCropped;
            border-radius: 4px;
        }
        .photo_content {
            float: left;
            width: calc(100% - 30px);
            position: absolute;
            bottom: 0;
            left: 15px;
            padding: 20px;
            border-radius: 4px;
            background: -webkit-linear-gradient(top, transparent, transparent 0, #000 100%, #000 0);
            background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0, transparent), color-stop(90%, #000), to(#000));
            background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0, transparent), color-stop(100%, #000), to(#000));
            background: linear-gradient(180deg, transparent 0, transparent 0, #000 100%, #000 0);
            h1 {
                font-size: 24px;
                color: #fff;
                font-weight: 900;
                transition: $transition1;
            }
        }
        span.photo_icon {
            @extend %centered;
            background: url('../images/player-icon.png') no-repeat center;
            float: left;
            width: 93px;
            height: 93px;
            transition: $transition1;
        }
    }
    .video_Only{
        position: relative;
        height: 264px;
        &:hover{
            span.video_icon {   
                background: url('../images/video_icon_hover.png') no-repeat center;
            }
        }
        span.video_icon {
            @extend %centered;
            background: url('../images/video_icon.png') no-repeat center;
            float: left;
            width: 93px;
            height: 93px;
            transition: $transition1;
        }
    }
}

.bottomNews {
    float: left;
    width: 100%;

    ul {
        float: left;
        width: 100%; 
        li {
            float: left;
            width: 50%;
            margin-top: 62px;
            transition: $transition1;
            padding-right:15px;
            &:hover {
                span.number {
                    background-color: #ff4202;
                    color: #fff;
                }
                p {
                    color: #ff4202;
                }
            }
            &:nth-child(2n+1){
                clear: left;
            }
            &:nth-child(even){
                padding-left:15px;
                padding-right:0;
            }
            span.number {
                float: left;
                width: 70px;
                height: 70px;
                text-align: center;
                line-height: 68px;
                border: 1px solid #ff4202;
                color: #ff4202;
                transition: $transition1;
                border-radius: 50%;
                font-size: 30px;
                font-weight: 700;
            }
            p {
                font-size: 24px;
                color: #212121;
                font-weight: 900;
                padding-left: 28px;
                float: left;
                line-height: 1.2em;
                width: calc(100% - 70px);
            }
        }
    }
}

#most_funny {
    .most_funny_wrap {
        float: left;
        width: 100%;
        border-bottom: 1px solid #939598;
        margin-bottom: 30px;
    }
    .main-title {
        h1 {
            float: left;
            color: #077cfc;
        }
        span {
            float: right;
            color: #077cfc;
            font-weight: 300;
            font-size: 14px;
            margin-top: 30px;
        }
    }
    .col_row2 {
        float: left;
        width: 30%;
        ul {
            li {
                float: left;
                width: 100%;
                padding: 0;
                margin-bottom: 32px;
            }
        }
    }
    .side_big_article {
        float: right;
        width: 65.6%;
        margin-left: 30px;
        transition: $transition3;
        &:hover{
            .content_big_article {
                h1{
                    color: #077cfc;
                }
            }
        .thumb_big_article {
            img{
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                opacity: 0.9;
            }
        }
        }
        .thumb_big_article {
            float: left;
            width: 100%;
            height: 418px;
            overflow: hidden;
            img {
                @extend %imgCropped;
                border-top-right-radius:4px;
                border-top-left-radius:4px;
                transition: $transition3;
                opacity: 1;
            }
        }
        .content_big_article {
            float: left;
            width: 100%;
            height: 300px;
            background: #e6e6e6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-bottom-right-radius:4px;
            border-bottom-left-radius:4px;
            h1 {
                font-size: 50px;
                font-weight: 900;
                color: #212121;
                text-align: center;
                line-height: 1.1em;
                padding: 0px 16px;
                transition: $transition1;
            }
            span {
                font-size: 18px;
                color: #939598;
                font-weight: 400;
                display: block;
                margin-top: 34px;
            }
        }
    }
}

.sideNewsWrap {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    ul {
        li {
            float: left;
            transition: $transition1;
            width: 100%;
            border-bottom: 1px solid #939598;
            margin-bottom: 30px;
            padding-bottom: 30px;
             &:hover {
                span.pix {
                    width: 60px;
                    background: #077cfc;
                }
                h1 {
                    color: #077cfc;
                }
                .date-time {
                    color: #077cfc;
                }
                .play_button{
                    background: url(../images/video_icon_hover.png) no-repeat 50%;
                }
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                .side_content {
                    float: left;
                    text-align: right;
                }
                .side_img {
                    float: right;
                    padding-right: 0;
                    padding-left: 15px;
                    position:relative;
                }
                span.pix {
                    margin-left: auto;
                }
            }
        }
    }
}

.side_img {
    float: left;
    width: 50%;
    height: 222px;
    padding-right: 15px;
    position: relative;
    img {
        @extend %imgCropped;
        border-radius: 4px;
    }

}

.side_content {
    float: right;
    width: calc(50% - 33px);
    height: 222px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: $transition1;
    .date-time {
        font-size: 16px;
        color: #939598;
        font-weight: 400;
        transition: $transition1;
    }
    h1 {
        font-size: 24px;
        font-weight: 900;
        color: #212121;
        margin-top: 15px;
        margin-bottom: 36px;
        line-height: 1.1em;
        transition: $transition1;
    }
    span.pix {
        float: left;
        width: 10px;
        height: 10px;
        background: #212121;
        transition: $transition1;
    }
   
}

#most_funny {
    .box_style:hover .article_box_content span,
    .box_style:hover .article_box_content h1 {
        color: #077cfc;
    }
}

.content_slider {
    width: 100%;
    margin: 0 auto;
    float: none;
    height: 500px;
    float: right;
    position: relative;
    .sport-slider {
        float: left;
        // width: calc(100vw - 18%) !important;
        width: 100vw!important;
        position: relative;
        // margin-left: -49.4vw;
        // left: 68%;
        left: 27%;
        left: 0;
        height: 500px;
        max-width: none;
        overflow: hidden;
        background: #f4f4f4;
        border: 10px solid #4aa88b; 
        border-radius: 6px;
        &.loadingClass {
            li {
                display: none;
                &:first-child {
                    display: block;
                }
            }
        }
        img {
            width: 100%;
            height: auto;
            position:relative;
            // max-width: 1200px;
        }
        .sport-slider-content{
            position:absolute;
            left: 0px;
            bottom:0px;
            padding:20px;
            padding-bottom:65px;
            width: 100%;
            float: left;
            width: 100%;
            background: -webkit-linear-gradient(top, transparent 0%, transparent 0%, #000 100%, #000 100%);
            background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0%, transparent), color-stop(90%, #000), to(#000));
            background: linear-gradient(to bottom, transparent 0%, transparent 0%, #000 100%, #000 100%);
            span{
                font-size: 16px;
                color: #fff;
                font-weight: 400;
                margin-bottom: 10px;
                display: block;
            }
            h1{
                max-width: 960px;
                color: #fff;
                font-size: 50px;
                font-weight: 900;
            }
        }
    }
    .flex-viewport{
        height: 100%;
        .slides {
            height: 100%;
            li {
                height: 100%;
                position: relative;
            }
        }
    }
    .flex-direction-nav{
        display: none;
    }
    .flex-control-nav{
        text-align: left;
        left: 20px;
        bottom:20px;
        z-index: 8;
        li{
            a{
                background:#fff;
                box-shadow:none;
            }
            a.flex-active{
                background:#4aa88b;
            }
        }
    }
    
}
#sport{
    .main-title{
        h1{
            float: left;
            color: #4aa88b;
        }
        span{
            float: right;
            color: #077cfc;
            font-weight: 300;
            font-size: 14px;
            margin-top: 30px;
            color: #4aa88b;
        }
    }
    .box_style:hover .article_box_content span,
    .box_style:hover .article_box_content h1{
        color: #4aa88b;
    }
    .box_style:hover{
        opacity: 0.8; 
    }
    .box_style{

        margin-top: 30px;
        &:nth-child(3n + 1){
            clear: left;
        }
        .thumb-holder{
            img{
                opacity: 1;
            }
        }
    }

    .container-left-side{
        min-height: 480px;
    }

}


#tjera{
    .main-title{
        h1{
            float: left;
            color: #212121;
            span.color-txt{
                color: #2808c9;
                font-weight: 900;
                font-size: 50px;
                margin-top: 0;
                margin-left: 20px;
            }
        }
        span{
            float: right;
            color: #077cfc;
            font-weight: 300;
            font-size: 14px;
            margin-top: 30px;
            color: #2808c9;
        }
    }
    .fit{
        h1{ 
            span.color-txt{
                color: #ef4136;
                font-weight: 900;
                font-size: 50px;
                margin-top: 0;
                margin-left: 20px;
            }
        }
        span{
            float: right;
            color: #077cfc;
            font-weight: 300; 
            font-size: 14px;
            margin-top: 30px;
            color: #ef4136;
        }
    }
    .cult{
        h1{
            span.color-txt{
                color: #f7941d;
                font-weight: 900;
                font-size: 50px;
                margin-top: 0;
                margin-left: 20px;
            }
        }
        span{
            float: right;
            color: #077cfc;
            font-weight: 300;
            font-size: 14px;
            margin-top: 30px;
            color: #f7941d;
        }
    }
    .geek{
        h1{
            span.color-txt{
                color: #8dc63f;
                font-weight: 900;
                font-size: 50px;
                margin-top: 0;
                margin-left: 20px;
            }
        }
        span{
            float: right;
            color: #077cfc;
            font-weight: 300;
            font-size: 14px;
            margin-top: 30px;
            color: #8dc63f;
        }
    }
    .others_slider{
        float: left;
        width: 100%;
        position:relative;
        height: 220px;
        margin-bottom: 30px;
        padding-bottom:30px;
        border-bottom:1px solid #939598;
        transition: $transition1;
        .slides,
        .slides li,
        .flex-viewport{
            height: 220px !important;
        }
        &:hover{
            .thumb-item{
                img{
                    opacity: 0.9;
                }
            } 
            .others-content-item{
                h1{
                    color: #2808c9;
                }
            }

        }
        .flex-viewport{
            height: 100%;
            ul.slides{
                height: 100%;
                li{
                    height: 100%;
                    display: none;
                    &:first-child{
                        display: block; 
                    }
                }
            }
        }
        .thumb-item{
            float: left;
            width: 50%;
            height: 220px;
            img{
                @extend %imgCropped; 
                border-radius: 4px;  
                opacity: 1;
                transition: $transition1;
            }
        }
        .others-content-item{
            float: left;
            width: 50%;
            height: 100%;
            padding:0 45px;
            background:#e6e6e6;
            position:relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: left;
            -ms-flex-align: left;
            align-items: left;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-top-right-radius: 4px; 
            border-bottom-right-radius: 4px; 
            span{
                color: #939598;
                font-size: 16px; 
                font-weight: 400;
                margin-bottom: 10px;
            }
            h1{
                color: #212121;
                font-size: 24px; 
                font-weight: 900;
                transition: $transition1;
                line-height: 1.2em;
            }
            
        }
    }
        .flex-control-nav{
            margin-left: 86px;
            overflow: hidden;
            bottom: 24px;
            a.flex-active{
                background:#2808c9;
            }
        }
        .article_box{
            margin-top: 0;
        }
        .box_style{
        margin-bottom: 32px;
        &:nth-child(3n + 1){
            clear: left;
        }
    }
    .cool-articles{
        .box_style{
            margin-bottom: 0;
            &:hover{
             .article_box_content h1{
                     color: #2808c9;
                }
            }
        }
    }
    .fit_holder{
        .box_style{
            &:hover{
             .article_box_content h1{
                     color: #ef4136;
                }
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6){ 
                margin-bottom: 0;
            }
        }

    }
}
.cult_wrap{
    .col_row2{
        float: right;
        width: 30%;
    }
    .boxes{
        float: left;
        width: 100%;
        li{
            float: left;
            width: 100%;
            padding: 0;
            &:last-child{
                margin-bottom: 0 !important;
            }
        }
    }
    .side_big_article{ 
        float: left;
        width: 65.6%;
        margin-right: 30px;
        &:hover{
            .content_big_article {
                h1{
                    color: #f7941d;
                }
            }
            .thumb_big_article {
                img{
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                    opacity: 1;
                }
            }
        }
        .thumb_big_article{
            float: left;
            width: 100%;
            height: 418px;
            overflow: hidden;
            transition: $transition3;
            img{
                @extend %imgCropped;
                transition: $transition3;
                opacity: 0.9;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
        .content_big_article{
            float: left;
            width: 100%;
            height: 300px;
            background: #e6e6e6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            h1{
                font-size: 50px;
                font-weight: 900;
                color: #212121;
                text-align: center;
                line-height: 1.1em;
                padding: 0 16px;
                transition: $transition3;
            }
            span{
                font-size: 18px;
                color: #939598;
                font-weight: 400;
                display: block;
                margin-top: 34px;
            }
        }

    }

    .box_style{

        &:hover{
         .article_box_content h1{
                color: #f7941d;
            }
        }
    }

}

.geek-holder{
       .box_style{
        &:hover{
         .article_box_content h1{
                color: #8dc63f;
            }
        }
    }
    .latest-wrap{
        &:hover{
            .latestNewsExcerpt h1 {
                color: #8dc63f;
            }
            span.date-time{
                color: #8dc63f;
            }
        }
    }
}

#tjera,
#sport,
#most_funny,
#most_popular,
.fullblock_dev {
    float: left;
    width: 100%;
}

#latestNews{
    margin-top: 30px;
    border-top: 1px solid #939598;
    padding-top: 30px;
    .boxes li{
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3){
            margin-bottom: 30px;  
        }
    }    
} 

/************** single style **************/
.single_content_holder {
    float: left;
    width: 100%;
    position: relative; 
}
.single-full-content { 
    float: right;  
    width: 70%;  
    padding-left: 0;      
    padding-right: 30px;   
    margin-bottom: 40px;
    .single-thumb {   
        float: left;    
        width: 100%;   
        height: 598px; 
        box-shadow: none;
        position: relative;
        iframe { 
            height: 598px!important;  
        }  
        img{
            @extend %imgCropped;  
            // width: 10000px; 
        }
    }
} 
//ikoona per category-video
// .video_category{
//  .article_header {
//      float: left; 
//      width: 100%;  
//      margin-top: 100px;          
//     .cat-post {  
//         color: #ff4202;
//         font-size: 16px;
//         font-weight: 900;
//         padding-left: 22px;
//         text-transform: uppercase;
//         background: url(../images/play_small.png) no-repeat left 2px;
//         background-size: 12px;
//         display: block;
//         margin-bottom: 15px;
//         background-color: #ff4202;
//         width: 20px; 
//         height: 20px;
//         background-position: center;
//         text-indent: center;
//         display: flex;
//         align-items: center; 
//     }
//     h1 {
//         font-weight: 900;
//         font-size: 50px;
//         color: #333333;
//         line-height: 1em; 
//     } 
//   } 
// }  


.article_header { 
     float: left; 
     width: 90%; 
     margin-top: 30px;       	 
    .cat-post {  
        color: #ff4202;
        font-size: 16px;
        font-weight: 900;
        padding-left: 22px;
        text-transform: uppercase;
        background: url(../images/cat-icon.png) no-repeat left 2px;
        background-size: 16px;
        display: block;
        margin-bottom: 15px;
    }
    h1 {
        font-weight: 900;
        font-size: 50px;
        color: #333333;
        line-height: 1em; 
    }    
}
.date_author {
        float: left;
        width: 90%;
        padding:20px 0;
        margin-top: 30px;
        margin-bottom: 0px;
        border-top:1px solid #939598;
        border-bottom:1px solid #939598;
         span{
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            color: #939598;
        }
        h5{
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            color: #ff4202;
            margin-top: 6px;
            span{
                margin-right:6px;
            }

        }
}
.related-article {
    float: left;
    width: 100%;
    padding:20px 0;
    margin-bottom: 30px;
    border-top:1px solid #ff4202; 
    border-bottom:1px solid #ff4202;
    span {
        color: #939598;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
    }
    p {
        color: #ff4202 !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        margin-top: 7px !important;
        margin-bottom: 0!important;
        font-family: 'Roboto', sans-serif !important;
    }
    &__thumb {
        float: left;
        width: 150px;
        min-height: 80px;
        margin-right: 20px;
        border-radius: 4px;
        position: relative;
        img {
            border-radius: 6px;
        }
    }
    &__text {
        float: left;
        width: calc(100% - 170px);
    }
}
.sponsored_article {
  float: left;
  width: 100%;
  max-width: 73%;
  margin-top: 15px;
  .side_content {
    height: auto;
    display: block;
    h1 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    span {
      float: left;
      margin-bottom: 10px;
    }
    p {
      float: left;
      width: 100%;
      color: #000;
      font-size: 18px;
      line-height: 1.4em;
      font-family: Merriweather,serif;
    }
  }
}
#single-content {
    float: left;
    width: 960px;
    width: 700px;
    margin-top: 50px;
    margin-left:0px;
    position:relative; 
    font-size: 18px;
    color: #000;
    float: left;
    h1{
        font-weight: 900;
        font-size: 50px;
        color: #333333;
        line-height: 1em;
    }
    figure {
        max-width: 100%!important;
    }
    p{
        font-size: 18px;
        color: #000;
        margin-bottom: 28px;
        float: left;
        width: 100%;
        line-height: 1.7em;
        font-family: 'Merriweather', serif;
    }
    a{
        color: #ff4202;
        font-size: 18px;
        font-family: 'Merriweather', serif;
        line-height: 1.7em;
    }
    
    blockquote{
        float: left;
        width: 100%;
        padding: 40px;
        border:1px solid #ff4202;
        border-radius:6px;
        margin-bottom: 25px;
        p{
            margin-top: 0;
            color: #000;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Merriweather', serif;
        }
    }
      ul{
        margin: 10px 0; 
        padding-left:20px;
        float: left;
        width: 100%;
        li{
            list-style-type: disc;
            line-height: 1.4em;
            font-size: 16px;
            color: #4b4b4b;
            margin-bottom: 5px;
            a{
                font-size: 16px;
                text-decoration: none;
                color: #4b4b4b;
            }
        }
    } 
        ol{
             margin: 10px 0; 
            padding-left:20px;
            float: left;
            width: 100%;
            li{
                list-style-type: decimal;
                line-height: 1.4em;
                font-size: 16px;
                color: #4b4b4b;
                margin-bottom: 5px;
            }
        }

}
.twitter-video{
    float: left;
}
.fb-post,
.fb-video{
    margin:10px 0 20px;
}
 .wp-caption{
        margin-bottom: 10px;
    }
.excerpt{
    float: left;
    width: 100%;
    p{
        color: #000;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Merriweather', serif;
    }
}
.embed{
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}
.hideelement{
    display: none;
}
.sideleft{
    position:absolute;
    left: -100px; 
}
.social-media{
    float: left;
    width: 100%;
    ul{
        float: left;
        width: 100%;
        padding-left: 0 !important; 
        li{
            float: left;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            list-style-type: none !important;
            a{
                float: left;
                width: 100%;
                height: 100%;
            } 
            &:last-child{
                margin-right: 0;
            }
        }
        li.fb{
            background: url(../images/single-fb.png) no-repeat 50% #3a5a98;
        }
        li.twitter{
            background: url(../images/single-twitter.png) no-repeat 50% #29a7df;
        }
        li.googleplus{
            background: url(../images/single-gplus.png) no-repeat 50% #cc3731;
        }
    }
}

#left_element{
    position: absolute;
    width: 180px;
    float: left;
    margin-left: -200px;
    margin-top: 60px;
    .tags_area{
        float: left;
        width: 100%; 
        margin-top: 20px;
        ul{
            float: left;
            width: 100%;
            padding-left:0 !important;
            li{
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                list-style-type: none !important;
                a{
                    background:#e6e7e8;
                    color: #939598;
                    padding:6px;
                    border-radius:6px;
                    font-size: 16px;
                    float: left;

                }
            }
        }
    } 
}


.text_shadow{
    float: left;
    width: 100%;
    position: absolute;
    top: 20px;
    background:transparent; 
    transition: $transition1;
    opacity: 0;    
    box-shadow: 20px 0px 94px 59px #fff;
    z-index: 8;
}
.text_shadow.is_stuck{
    transition: $transition1; 
    opacity: 1;  
} 

.other-items{
    float: left;
    width: 960px;
}
.CatNews{
        margin:30px 0 ;
        border-top:1px solid #939598;
        border-bottom:1px solid #939598;
    ul{
        li{
            margin-top: 32px;
            margin-bottom: 32px;
            padding-right:20px;
        }
    }

}
.four-article{
    li{
        width: 25%;
    }
    .box_style .thumb-holder{
        height: 200px;
    }
}
.cat_container{
    width: 100%;
    margin:0 auto; 
    margin-bottom: 40px;
    max-width: 960px;
    float: none;
    display: block;
    .related-article{
        margin: 30px 0;
    }
}
.latest-wrap-holder{
    float: left;
    width: 100%;
    margin-top: 30px;
    border-top:1px solid #939598; 
    padding-top:30px;
    .latest-wrap{
        &:nth-child(2){
            padding-bottom: 0;
            border:0;
            margin-bottom: 0;
        }
    }
}

.article_rightcontent{
    float: left;
    width: 100%;
    margin: 30px 0 0;
    padding:30px 0 0;
    border-top:1px solid #939598; 
    li{
        float: left;
        width: 50%; 
        padding: 0 15px;
        transition: $transition1; 
        &:hover{
            .play_button{
                background: url(../images/video_icon_hover.png) no-repeat 50%;
            }
        }
        .side_img{
            padding-right:0;
        } 
        .side_content{
            float: left;
            width: 50%;
            padding:0 25px;
            background:#e6e6e6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: left;
            -ms-flex-align: left;
            align-items: left;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-top-right-radius: 4px; 
            border-bottom-right-radius: 4px;
            h1{
                margin-bottom:0;
            }
        }
    }
}
.other-sport-boxes{
    float: left;
    width: 100%;
}
body.search{
    .boxes li{
        margin-bottom: 20px;
       /*  &:nth-child(3n+1){
            clear: left;
        } */
    }
}
#infscr-loading{
    display: none !important;
}
.box_style_paginated{
    margin-bottom: 20px;
    &.fourth-box { 
        clear: left;
    }
}


// Latest Changes

main{
    .rek-holder {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align:center;
        & > .g {
            float: left;
            width: auto; 
            margin-top: 30px;
            /* margin-bottom: 15px; */
        }
    }
}
#single-content{
    .rek-holder>.g{
        margin-bottom: 30px;
        margin-top: 0;
    }
}
.container-right-side{
 .rek-holder>.g{
        margin-bottom: 30px;
    }   
}

.cat_boxes{
    margin-top: 30px;
}
.one_article{
    margin-bottom: 0;
}
.oneSectionBox{
    margin-bottom: 0;
}
body.search,
body.archive{
    .header-down{
        opacity: 1;
        position:fixed;
        -webkit-transition: all 350ms ease-in-out;
        transition: all 350ms ease-in-out;
    }
    .header-up{
        opacity: 0;
        -webkit-transition: all 350ms ease-in-out;
        transition: all 350ms ease-in-out;
        z-index: -1; 

    }

} 
body.nav_toggled .top_menu.header-down{
    transition: none;
}

.container-left-side{
    float: left;
    width: 30%;
    min-height: 100vh;
}

.container{
    width: 100% !important;
    max-width: 100% !important;
}
.container-right-side{
    width: 70%;
    float: right;
    padding-left: 10px;
}
.content{
    width: 960px;
    float: left;
}
.left-side-header{
    float: left;
    width: 100%;
}
body.nav_toggled .left-side-header{
    width: calc(25% - 30px);
}
.main_nav{
    margin-left:15px; 
}
.footerWrap{
    float: none;
    display: block;
    width: 1170px;
    margin:0 auto;
}
#load-more-news-banner{
    position:relative;
}
#load-more-news-banner.loading:before{
    content: "";
    float: left;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 63px;
    left: 50%;
    margin-left: -20px;
    background: url(../images/ripple.svg) no-repeat 0 0;
    background-size: 100%
}
.rc-clearfix{
    float: left;
}
#latestNews{
    .boxes{
        li{   
            &:nth-child(3n + 1){
                clear: left;
            }
        }
    }
}
.g-34,
.g-32{
    max-height: 250px; 
}
#rcjsload_2aef10 h3{
    display: none !important;
}
.left-rek-holder{
    float: left;
    max-width: 160px;
    position:absolute;
    margin-left: -170px;
    margin-top: 10px;
}
.right-rek-holder{
    .g-37{
        margin-top: 0;
    }
}

.big_single_wrap{
    float: left;
    width: 100%; 
    // position: relative
    #single-content {
    	margin-top: 30px;
    }
    .right-rek {
    	margin-top: 30px;
    }
}
.right-rek{ 
    width: 300px;
    float: left;
    height: calc(100% - 80px);
    position: absolute;
    right: 0;
    margin-top: 40px;
    margin-right: -30px;
    background: red;
}

#right_side_rek.is_stuck{ 
   margin-top: -80px !important;  
   .g-37{
    margin-top: 50px;
   }
}
.right-rek-holder{
    margin-top: 150px;
}

#left_banner_rek{  
    position: absolute;  
    width: 300px;
    height: 600px;
    background: red;
    float: left;
    margin-left: -330px;
    z-index: 1;
}
.hidden_wrap{
    position: absolute;
    float: left;
    width: 100%;
    height: 100%;
    .div_first{
        position: relative;
        float: left;
        height: 1600px;
    }
    .div_second{
        position: relative;
        float: left;
        height: 2500px;
    }
}
.single-content-wrap{
    width:1000px;
    position: relative;
    font-size: 18px;
    float: left;
}

// Zgjedhjet Slice

.zgjedhjet_wrapper {
    float: left;
    width: 100%;
    z-index: 123;
    margin-top: 8px;
    padding-top: 16px;
    background: white;
    position: relative;
}
.zgjedhjet_title {
    float: left;
    width: 100%;
    margin-left: 30px;
    background: white; 
    h1 {
        float: left;
        width: auto;
        color: #212121;
        font-size: 45px;
        font-weight: 600;
        margin-top: 17px;
        font-weight: normal;
        text-transform: uppercase;
        font-family: 'Archivo Black', sans-serif;
    }
}
.zgjedhjet_icon {
    float: left;
    width: auto;
    margin-right: 17px;
}
.zgjedhjet_slider_holder {
    float: left;
    width: 100%;
    padding: 50px;
    position: relative;
    background: #e5e5e5;
    .container-left-side {
        min-height: auto;
    }
}
.partite_sidebar {
    float: right;
    margin-right: 5px;
}
.partite_list {
    float: left;
    width: 195px;
    li {
        float: left;
        width: 100%;
        color: black;
        font-weight: 700;
        padding: 8px 10px;
        position: relative;
        text-transform: uppercase;
        transition: $default-transition;
        &:after {
            top: 7px;
            left: 11px;
            opacity: 0;
            content: "";
            float: left;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            position: absolute;
            background: #ff4202;
            transition: $default-transition;
        }
        &.active,
        &:hover {
            color: #ff4202;
            padding-left: 30px; 
            text-decoration: none;
            transition: $default-transition;
            &:after {
                opacity: 1;
                transition: $default-transition;
            }
        }
        label {
            cursor: pointer;
        }
    }
    .filter_input {
        display: none;
    }
}
.small_devices_sidebar {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    .partite_list {
        width: 100%;
        margin-left: -12px;
        li {
            width: auto;
        }
    }   
}
.small_screen_element {
    display: none;
} 
.zgjContainer {
    float: left;
    width: auto;
    position: relative;
    .before_span {
        top: 0;
        left: 0;
        width: 20%;
        z-index: 11;
        float: left;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg,#e5e5e5 0,hsla(0,0%,100%,0) 27%);
    }
    .after_span {
        top: 0;
        right: 0;
        width: 20%;
        z-index: 11;
        float: left;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg,hsla(0,0%,100%,0) 73%,#e5e5e5);
    } 
}
.zgjedhjet_slider {
    float: left;
    width: 100%;
    min-height: 190px;
    position: relative;
    #ldk_tag:checked ~ :not(.ldk_tag) {
        display:none
    }
    #pdk_tag:checked ~ :not(.pdk_tag) {
        display:none
    }
    #vv_tag:checked ~ :not(.vv_tag) {
        display:none
    }
    #other_tag:checked ~ :not(.other_tag) {
        display:none
    }
    .article_box_content {
        .date-time {
            font-size: 13px;
            color: #939598;
            font-weight: 400;
            display: block;
            margin-top: 10px;
        }
        h1 {
            font-size: 15px;
            font-weight: 900;
            color: #212121;
            margin-top: 10px;
            line-height: 1.2em;
        }
    }
    .thumb-holder {
        img {
            width: 100%;
            height: 160px;
            object-fit: cover; 
            border-radius: 4px;
        }
    }
    .slides {
        float: left;
    }
    li {
        float: left;
        max-width: 25%;
        overflow: hidden;
    }
    .loadingClass {
        li {
            display: none;
            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 25%;
                float: left;
                display: block;
                padding-left: 7px;
                padding-right: 7px;
            }
            &:first-child {
                padding-left: 0px; 
            }
            &:nth-child(4) {
                padding-right: 0px;
            }
        }
    }
}
.flex-direction-nav {
    a {
        top: auto;
        bottom: 0px;
        z-index: 123;
        outline: none;
        border-radius: 100%;
        text-indent: -9999em;
        opacity: 1 !important;
        text-align: left !important;
        transition: $default-transition;
        &:hover {
            background-color: #ff4202;
            transition: $default-transition;
        }
    }
    // .flex-disabled {
    //     opacity: 0.6 !important; 
    //     z-index: 1;
    //     &:hover { 
    //         background-color: black; 
    //     }
    // }
    .flex-prev {
        left: -120px;
        background: black url('../images/left_arrow.svg') no-repeat center;
        background-size: 29px;
    }
    .flex-next {
        right: auto;
        left: -70px;
        background: black url('../images/right_arrow.svg') no-repeat center;
        background-size: 29px; 
    }
}
.gif_title {
    float: left;
    width: auto;
    max-width: 51%;
	&.cool {
		max-width: 30%;
	}
}
.main-title {
    a {
        position: relative;
        > span {
            right: 0;
            bottom: 0;
            position: absolute;
        }
    }
}
/* Per ne template */
.page-template-page-per-ne {
    #footer,
    #header { 
        display: none;
    }
}
.section_wrapper {
    @extend %fullBlock;
    position: relative;
}
.aboutus_container {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
}
.contact_container {
    margin: 0 auto;    
    max-width: 1170px;
}
.aboutus_banner_wrapper {
    height: 600px;
    @extend %fullBlock;
    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    img{
        @extend %imgCropped;
    }
}
.aboutus_banner_content{
    @extend %fullBlock;
    position: absolute;
    top: 0;
    left: 0;
    padding: 115px 0 50px 0;
    height: 100%;
    .aboutus_container{
        height: 100%;
    }
}
.aboutus_banner_logo{
    width: 110px;
    float: left;
    clear: both;
    background: #fff;
    position: relative;
    padding: 14px;
    &:before{
        content: " ";
        background: #fff;
        position: absolute;
        left: -100vw;
        width: 100vw;
        height: 100%;
        z-index: 2;
        top: 0;
    }
}
.aboutus_banner_logo.colored{
    background: transparent;
    padding: 0;
    height: 60px;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        border: 10px solid #ff4202;
    }
    img{
        display: block;
    }
    &:before{
        background: $mainColor;
    }
}
.aboutus_banner_description{
    @extend %fullBlock;
    h1{
        color: #333;
        font-size: 50px;
        margin-top: 40px;
        font-weight: 700;
        line-height: 1.20em;
        span{
            color: $mainColor;
        }
    }
}
.aboutus_banner_bottom_url{
    @extend %fullBlock;
    bottom: 0;
    position: absolute;
    a{
        color:#333;
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        transition:$transition1;
        &:hover{
            color: $mainColor;
        }
    }
}
.top_bar_section{
    @extend %fullBlock;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    .top_bar_content{
        float: left;
        height: 60px;
        display: flex;
        padding: 20px;
        padding-right: 0;
        position: relative;
        align-items: center;
        width: calc(100% - 110px);
        justify-content:space-between;
        border-top: 1px solid $silverColor;
        border-bottom: 1px solid $silverColor;
        &:before{
            content: " ";
            position: absolute;
            height: 60px;
            right: -100vw;
            width: 100vw;
            border-bottom: 1px solid $silverColor;
            z-index: 2;
            top: -1px;
        }
        &:before{
            content: " ";
            position: absolute;
            height: 60px;
            right: -100vw;
            width: 100vw;
            border-top: 1px solid $silverColor;
            z-index: 2;
            top: -1px;
        }
        h1{
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            color:$lightenBlack;
        }
        a{
            text-decoration: none;
            color:$silverColor;
            font-size: 15px;
            font-weight: 600;
            transition:$transition1;
            &:hover{
                color: $mainColor;
            }
        }
    }    
}
.paralax_outer_wrapper{
    @extend %fullBlock;
    position: relative;
    min-height: 500px;
    margin-top: 130px;
    &:after{
        content: "";
        width: 100%;
        position: absolute;
        bottom:0;
        left:0;
        display: block;
        height: 280px;
        background-color: $lightenBlack;
    }
}
.aboutus_box_paralax{
    @extend %fullBlock;
}
.aboutus_main_title{
    @extend %fullBlock;
    h1{
        font-family: 'Merriweather', serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.34em;
        color: $lightenBlack;
        // transition:transition2;
        // &:hover{
        //     color: $mainColor;
        // }
    }
}
.colored{
    h1{
        color:$mainColor;
    }
}
.white{
    h1{
        color:#fff!important;
    }
}

.has_border_top{
    border-top: 5px solid $mainColor;
    h1{
        padding-top: 15px;
    }
}
.aboutus_main_content{
    @extend %fullBlock;
    p{
        font-size: 16px;
        font-weight: 400;
        margin-top: 30px;
        line-height: 1.57em;
        color: $lightenBlack;
        background: transparent;
        mix-blend-mode: lighten;
        font-family: 'Merriweather', serif;
    }
}
.bottom_ipad{
    position: relative;
    z-index: 2;
    float: left;
    margin-top: 50px;
    padding-left: 50px;
}
.paralax_wrapper{
    @extend %fullBlock;
    padding-left: 200px;
}
.middle_banner{
    float: left;
    position: absolute;
    bottom: 75px;
    left: 0;
    max-width: 50%;
    p{
        font-size: 40px;
        line-height: 1.3em;
        color: #fff;
        font-weight: 300;
        b{
            float: left;
        }
    }
}
.staf_and_article_wrapper{
    @extend %fullBlock;
    padding-top: 60px;
}
.aboutus_article_wrapper{
    @extend %fullBlock;
    position: relative;
    border-bottom: 6px solid $mainColor;    
    &:after{
        content: "";
        background: #ff4202;
        height: 6px;
        position: absolute;
        right: -100vw;
        width: 100vw;
        z-index: 2;
        bottom: -6px;
    }
    .aboutus_box_paralax{
        margin-top: 70px;
    }
    .aboutus_article_thumb{
        height: 360px;
        position: relative;
        img{
            max-width: 100vw;
            position: absolute;
            right: calc(-50vw + 100%);
            width: 50vw;
            height: 100%;
            object-fit: cover;
            z-index: 2;
            top: 0;
        }
    }
}
.aboutus_staf_wrapper{
    @extend %fullBlock;
    margin-top: 40px;
    margin-bottom: 50px;
    padding-right: 140px;
    h4{
        font-size: 18px;
        color: $lightenBlack;
        text-transform: uppercase;
        font-weight: 600;
    }
}
.staf_thumb{
    @extend %fullBlock;
    height: 180px;
    margin-top: 30px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit:cover;
        transition:$transition1;
        &:hover{
            transform: scale(1.1);
        }
    }
}
.staf_content{
    @extend %fullBlock;
    h1{
        font-size: 24px;
        color: $mainColor;
        font-weight: 600;
        margin-top: 18px;
    }
    p{
        color: $lightenBlack;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.4em;
        font-family: 'Merriweather', serif;
    }
}
.facts_wrapper{
    @extend %fullBlock;
    background: $lightenBlack;
    padding: 120px 0 60px 0;
}
.facts_title{
    @extend %fullBlock;
    margin-bottom: 50px;
    h3{
        color: #fff;
        margin-top: 10px;
        font-size: 24px;
        line-height: 1.4em;
        font-weight: 500;
        font-family: 'Merriweather', serif;
    }
}
.top_box{
    @extend %fullBlock;
    border-bottom: 2px solid $mainColor;
    padding-bottom: 10px;
    margin-top: 20px;
    img{
        width: 50px;
        height: 50px;
    }
}
.bottom_box{
    @extend %fullBlock;
    h1{
        font-size: 50px;
        color: #fff;
        font-weight: 600;
        line-height: 1.3em;
        margin-top: 10px;
    }
    p{
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4em;
        font-family: 'Merriweather', serif;
    }
}
.map_wrapper{
    @extend %fullBlock;
    img{
        margin-top: 110px;
        height: 380px;
        float: left;
    }
}
.is_box{
    width: 570px;
    background: white;
    position: relative;
    margin: 0;
    float: right;
    margin-top: 90px;
    padding-right: 0;
    padding: 25px;
    h4{
        color:$lightenBlack;
    }
}
.first_item{
    &:after{
        position: absolute;
        content: "";
        left: -350px;
        top: 50%;
        width: 350px;
        height: 2px;
        background: $mainColor;
    }
    &:before{
        position: absolute;
        content: "";
        width: 45px;
        height: 2px;
        background: $mainColor;
        transform: rotate(-45deg);
        left: -388px;
        top: 50px;
    }
}
.second_item{
    &:after{
        position: absolute;
        content: "";
        left: -480px;
        top: 50%;
        width: 480px;
        height: 2px;
        background: $mainColor;
    }
    &:before{
        position: absolute;
        content: "";
        width: 55px;
        height: 2px;
        background: $mainColor;
        transform: rotate(45deg);
        left: -527px;
        top: 24px;
    }
}
.right_desc{
    float: right;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;
    font-family: 'Merriweather', serif;
    a{
        text-decoration: none;    
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4em;
        font-family: 'Merriweather', serif;
        transition:$transition2;
        &:hover{
            color:$mainColor;
        }
    }
}
.holder_wrapper{
    padding-left: 200px;
}
.aboutus_box_left{
    @extend %fullBlock;
    margin-top: 120px;
}
.aboutus_half_thumb{
    width: 650px;
    float: left;
    margin-top: 30px;
    img{
        width: 100%;
    }
}
.box_reklama{
    @extend %fullBlock;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
}
.box_reklama_thumb{
    @extend %fullBlock;
    height: 230px;
    padding: 10px;
    text-align: center;
    border: 1px solid #e6e6e6;
    img {
        min-height: 200px;
    }
    &.desktop_thumb {
        padding-top: 20px;
        img {
            min-height: auto;
        }
    }
}
.bottom_box_reklama {
    @extend %fullBlock;
    background: #E6E6E6;
    h4{
        font-size: 18px;
        color: #333;
        text-transform: uppercase;
        font-weight: 600;
        padding: 16px;
        float: left;
        &.price_title {
            color: white;
            font-size: 16px;
            padding: 17px 10px;
        }
    }
    a {
        float: right;
        width: 108px;
        height: 50px;
        background-color: $lightenBlack;
        i {
            width: 40px;
            height: 100%;
            transition:$transition2;
            float: right;
            background-image: url('../images/arrow-top.svg');
            background-repeat: no-repeat;
            background-size: 30px;
            background-position: center center;
        }
        &:hover{
            i{
                transform: rotate(180deg);
            }
            .box_hidden_reklama{
                opacity: 1;
                position: absolute;
            }
        }
    }
}
.box_hidden_reklama{
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    float: left;
    left: 0;
    background: $lightenBlack;
    opacity: 0;
    top: 0;
    transition:$transition2;
    padding: 20px;
    ul{
        float: left;
        width: 100%;
    }
    li{
        float: left;
        width: 100%;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content:space-between;
        span{
            font-size: 16px;
            font-weight: 600;
            color:#fff;
            &:before{
                content: "€ ";

            }
        }
        p{
            font-size: 16px;
            font-weight: 500;
            color:#fff;
        }
    }
}
.is_boxes_title{
    margin-top: 55px;
}
.has_bottom_space{
    margin-bottom: 70px;
} 
.banner_content {
    &_top {
        max-width: 50%;
        font-size: 40px;
        font-weight: 100;
        color: $mainColor;
        .after_qoutes,
        .before_qoutes {
            font-size: 40px;
            font-weight: 100;
        }
    }
}
.contact_holder {
    float: left;
    width: 100%;
    margin-top: 113px;
    &.form_about_us {
        width: 45%;
        float: left;
        z-index: -1;
        padding: 0 15px;
        position: absolute;
        transition: all .3s ease-in-out;
        &.active {
            z-index: 123;
            transition: $default-transition;
        } 
        input {
            width: 49%;
            color: white;
            border: none;
            outline: none;
            padding: 5px 0;
            padding-bottom: 10px;
            background: transparent;
            border-bottom: 2px solid white;
            transition: all .3s ease-in-out;
            @include placeholder {
                color: $placeholderColor;
            }
            &:focus {
                transition: all .3s ease-in-out;
                border-bottom: 2px solid $mainColor;
            }
        }
        input[type="text"] {
            float: left;
        }
        input[type="email"] {
            float: right;
        }
        textarea {
            width: 100%;
            float: left;
            color: white;
            border: none;
            height: 80px;
            resize: none;
            outline: none;
            padding: 5px 0;
            margin-top: 20px;
            padding-bottom: 10px;
            background: transparent; 
            border-bottom: 2px solid white;
            transition: all .3s ease-in-out;
            @include placeholder {
                color: $placeholderColor;
            }
            &:focus {
                transition: all .3s ease-in-out;
                border-bottom: 2px solid $mainColor;
            }
        }
        input[type="submit"] {
            float: left;
            clear: both;
            color: white;
            width: 150px;
            border: none;
            padding: 20px;
            outline: none; 
            cursor: pointer;
            font-size: 16px;
            margin-top: 50px;
            text-align: center;
            background: $mainColor;
        }
    }
    &.contact_default {
        float: left;
        width: 100%;
        z-index: 123;
        padding: 0 15px;
        position: absolute;
        &.in-active {
            z-index: -1;
            transition: $default-transition;
        }
    }
} 
.contact_wrapper {
    float: left;
    width: 100%;
    height: auto;
    min-height: 577px;
    background: url('../images/aboutus-bottom-banner.jpg') no-repeat center;
    background-size: cover;
}
.blic_address {
    float: left;
    width: 25.6%;
    margin-top: 70px;
    padding-top: 15px;
    border-top: 1px solid white;
    .address_item {
        float: left;
        width: 100%;
        margin-bottom: 30px;
    }
    h5 {
        width: 100%;
        color: white;
        font-size: 16px;
        margin-bottom: 35px;
    }
    p {
        float: left;
        width: 100%;
        color: white;
        a {
            color: white;
        }
    }
}
.contact_form {
    float: left;
    width: 100%;
}
.contact_button {
    float: left;
    clear: both;
    color: white;
    width: 150px;
    border: none;
    padding: 20px;
    outline: none; 
    cursor: pointer;
    font-size: 16px;
    margin-top: 50px;
    text-align: center;
    background: $mainColor;
}
.cancel_button {
    float: left;
    clear: both;
    width: 30px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 60px;
    span {
        width: 100%;
        height: 2px;
        float: left;
        background: white;
        &:first-child {
            margin-top: 11px;
            margin-bottom: 2px;
            transform: rotate(45deg);
        }
        &:nth-child(2) {
            margin-top: -4px;
            transform: rotate(-45deg);
            transition: $default-transition;
        }
    }
}
.partners_wrapper {
    float: left;
    width: 100%;
    min-height: 200px;
}
.partners {
    float: left;
    width: 100%;
    margin-top: 79px;
    .partners_item {
        width: 25%;
        float: left;
        padding: 0 15px;
        margin-bottom: 20px;
        a {
            float: left;
            width: 100%;
            padding: 0px;
            height: 100px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border: 1px solid #e6e6e6;
            img {
                max-height: 100%;
            }
        }
    } 
}  
/* END Per ne template */
 
.single { 
    .video_blic{
        &.single_tabs{
            padding-right: 0;
        }
    } 
} 
.video_wrapper{
    width: 100%;
    float: left;
    margin-top: 90px;  
    color:#192b3f;     
    height: 668px;     
    & > a {
        color:#192b3f;     
        float: left;   
        font-size: 57px;
        font-weight: 400;          
        margin-top: -30px;
        margin-left: 30px;    
        text-align: center; 
        margin-bottom: -8px; 
        text-transform: uppercase;   
        font-family: 'Archivo Black', sans-serif;
    }  
}  
// .videos{
//     width: 493px;
//     float: left;  
// }
.video{
    // height: auto;  
    // // width: 1050px; 
    // float: left; 
} 
.video_blic {
    float: left;
    width: 100%;   
    height: 668px;   
    background-color: #192b3f;
    &.single_tabs {
    	width: 25%;
    	height: 598px; 
        overflow: hidden; 
        padding-right: 15px;
    }
    .title_video {  
        height: auto; 
        // width: 23%; 
    }           
}  
.title_holder {
    @include rem('padding-top', 40px);
    @include rem('margin-left', 20px);
    @include rem('margin-bottom', 20px);
    width: 100%;
    float: left;
    // width: 420px;
    // padding-top: 40px;
    // margin-left: 20px;
    h3 {
        @include rem('height', 40px);
        @include rem('line-height', 40px);
        @include rem('font-size', 20px); 
        @include rem('padding', 0 8px);   
        // @include rem('margin-right', 25px);  
        z-index: 2;  
        width: auto;  
        float: left;
        color: #fff;
        // height: 40px; 
        // font-size: 18px;
        // line-height: 40px;
        // padding: 0 8px;
        cursor: pointer; 
        text-align: center;
        position: relative;
        background-color: #192b3f;
        text-transform: uppercase; 
        font-family: 'Archivo Black', sans-serif;        
        &:hover { 
           background-color: #fc4102;         
        }
      }
    } 
    .main_video { 
        width: 100%;      
        // width: auto;   
        height: 572px;      
        margin-top: 40px;  
        position: relative;
        display: inline-block;   
        cursor: pointer; 
        z-index: 2;
        .overlay {  
            width: 100%; 
            height: 100%;  
            position: absolute;
            border-radius: 12px; 
            background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,1));
        } 
        img {   
          width: 100%;
          height: 572px;
          // height: 100%; 
          // width: 540px;    
          // width: auto;  
          // height: auto;          
          // height: 440px;  
          // z-index: 2; 
          // position: relative; 
          object-fit: cover;
          border-radius: 12px; 
     }   
     .video_content {
        position: absolute;
        bottom: 0;
        left: 0; 
        .play_video {
            margin-top: 30px;  
            background-color: rgba(255,255,255, 0.1); 
            border-radius: 50%;  
        }
         span {
          background: url(../images/play-button.svg) no-repeat 50%;
          float: left; 
          width: 100px; 
          height: 100px;  
          transition: all .3s cubic-bezier(.3,0,.2,1); 
          margin-bottom: 63px;
          margin-left: 29px;
          margin-right: 27px;  
          margin-top: 10px;            
         }  
         p{
            font-family: 'Roboto', sans-serif;
            color: #fff;              
            font-size: 45px;
            font-weight: 700; 

         } 
         h3{
             font-family: 'Roboto', sans-serif; 
             color: #fff; 
             font-weight: 400;
             font-size: 14px; 
             margin-bottom: 20px; 
         } 
       }  
    }
    .taby { 
        &.active { 
            h3 {
                background-color: #fc4102;  
            }
        }
    }
.video_holder{
    width:100%; 
    height:auto;     
    float: left;  
    overflow: hidden; 
    // width: 400px;
    // height: auto; 
    // margin-left: 20px; 
    // height: 450px;     
     position: relative;
    .list-overlay{ 
    position: absolute;
    width: 100%;    
    height: 122px; 
    float: left; 
    display: inherit; 
    z-index: 2;   
    text-align: left;  
    margin-top: -122px;  
    // background: linear-gradient(0deg,#00050a,rgba(25,43,63,.1));   
    background: linear-gradient(0deg,#15273a,rgba(25,43,63,.1));   
    }   

} 

 
.video_description {               
    z-index: 2;
    width: 100%;
    height: 562px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    padding-right: 25px;
    box-sizing: content-box;
    li {
        float: left;
        width: calc(100% + 27px);
        &.blank_list {
            float: left;
            width: 100%;
            height: 110px;
        }
        &:hover {
            background-color: #142433;
        }
        .video_inner_wrapper {
            float: left;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #142433;
        }
        a {
            z-index: 2;
            float: left;
            padding: 20px;
            margin-left: 0;
            padding-bottom: 0;
            position: relative;
            .image_holder {
                float: left;
                width: 140px;  
                height: 80px; 
                position: relative;
                margin-right: 20px;   
                img {
                    width: 140px;
                    height: 80px; 
                    border-radius: 4px; 
                }
            }
            .play {            
                top: 50%;
                left: 50%;
                width: 35px;
                height: 35px; 
                border-radius: 50%;  
                position: absolute;   
                transform:translate(-50%, -50%); 
                background: url(../images/play_small.png) no-repeat 50%;
                background-color: hsla(0,0%,100%,.4);
            }             
        }
        p {
            float: left;
            color: #fff;
            font-size: 19px;
            font-weight: 500;
            line-height: 1.158em;
            width: calc(100% - 160px);
            font-family: 'Roboto', sans-serif;
        }
  }
}  

.opinion-lines{
    width: 40px;
    height: 40px;
    border-right: 2px solid #697C8D; 
    border-bottom: 2px solid #697C8D; 
    float: right;    
    display: inline-block;
    position: absolute; 
    bottom: 32px;
    margin-left: -14px; 
    // margin-bottom: 32px; 
}
.rectangle_hover{ 
    display: none; 
    width: 50px;
    height: 50px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    text-align: center;       
    margin-left: 60px; 
    h3{  
    font-size: 16px; 
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    width: 125px;
    color: #232323;      
    position: absolute; 
    bottom: 0;        
    text-transform: uppercase;  
    bottom: 75px;      
 }  
}

body.logged-in{
    #sidebar-video{
        nav{
            margin-top: 20px;
            position: absolute;
            left: 0; 
        } 
    }  
} 
 
   
 .tabs_content {
        .video_holder { 
            display: none;     
            &.active {    
                display: block;   
            }     
        } 
    }  
 .main_video_category{ 
    position: relative; 
    // height: 494px; 
    width: auto;   
    height: 100%;  
    .overlay{ 
        width: 100%;
        height: 100%; 
        background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,1));
        position: absolute;
        border-radius: 12px; 
    } 
    img { 
        border-radius: 12px;  
        height: 494px;
        width: 1200px;  
    }
    .video_content { 
        left: 37px;
        bottom: 60px; 
        width: 900px;    
        position: absolute;
        .play_video {
            margin-top: 30px;  
            margin-right: 35px;  
            border-radius: 50%;  
            background-color: rgba(255,255,255, 0.1); 
        }
        span {
          background: url(../images/play-button.svg) no-repeat 50%;
          float: left; 
          width: 100px; 
          height: 100px;  
        }
        p {
            color: #fff;              
            float: right;
            font-size: 45px;
            font-weight: 700; 
            text-align: left;
            font-family: 'Roboto', sans-serif;
            width: calc(100% - 135px) !important;
        } 
        h3 {
             font-family: 'Roboto', sans-serif; 
             color: #fff; 
             font-weight: 400;
             font-size: 14px; 
             margin-bottom: 20px; 
             text-align: left;
        } 
       } 
}

  


//video 

.newsList{
    position: relative;
  .play{ 
    background: url(../images/play_small.png) no-repeat 50%;
    width: 45px;
    height: 45px;
    background-color: hsla(0,0%,100%,0.4);
    border-radius: 50%; 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%,-50%);
  }
} 
.article_box_content{
   .date_play{ 
        float: left;
        width: 100%;
        margin-bottom: 12px;
    .play_small{ 
         background: url(../images/play_small.png) no-repeat 50%;
         background-size: 8px; 
         width: 15px;
         height: 15px;  
         margin-right: 12px; 
         background-color: #d1d3d4;    
         float: left;
      }  
    }
}

.video_bottomNews{  
    .number{
        border-radius: 0 !important;   
        background: url(../images/triangle_orange.png) no-repeat 50%;
        background-size: 30px !important;     
        background-color: #ffffff;   
    }    
  li{ 
    transition: $transition1;  
         &:hover { 
                span.number { 
                    background-image: url('../images/triangle.png') !important;
                    background-repeat: no-repeat !important;                     
                    background-size: 30px !important;   
               }          
         }    
     } 
} 

.video-latest-wrap{
    .play{ 
    background: url(../images/play_small.png) no-repeat 50%;
    width: 60px;
    height: 60px; 
    background-color: hsla(0,0%,100%,0.4);
    border-radius: 50%; 
    position: absolute;
    top: 50%;
    left: 50%;  
    transform: translate(-50%,-50%);
  } 

.latestNewsExcerpt{
  .date_play{ 
        float: left;
        width: 100%;
        margin-bottom: 12px;
    .play_small{ 
         background: url(../images/play_small.png) no-repeat 50%;
         background-size: 8px; 
         width: 15px;
         height: 15px;  
         margin-right: 12px; 
         background-color: #d1d3d4;    
         float: left;
      }  
    }
  }
}
.video_fundit{
    li:first-child:after{
       display: none; 
    }
} 
#videot_me_shikuara{
    float: left;
    width: 100%; 
}
#videot_fundit{
    float: left;
    width: 100%;   
}

.video_fundit{  
      width: 248px;
      margin-left: -80px;   
      margin-top: -70px;       
    li{ 
        margin-bottom: 10px; 
      &:first-child{  
        a{
            font-size: 16px !important;    
            padding-left: 10px !important;        
            display: inline !important;   
 
            &:after{     
              content: ""; 
              float: left;  
              background: url(../images/video_fundit.svg) no-repeat; 
              background-position: center;
              height: 25px !important; 
              width: 26px !important; 
              margin-top: 0 !important;                    
              background-color: #000;       
              background-size: 20px;                  
              transition: $transition1;      
            }   
             &:hover{                 
                &:after{
                    margin-top: 0 !important; 
                    transition: $transition1;   
                } 
            }  
         }       
     }
     &:last-child{ 
        a{  
            font-size: 16px !important;   
            padding-left: 10px;     
            display: inline;                                
            &:after{ 
              content: "";
              float: left;     
              background: url(../images/video_shikuara.svg) no-repeat; 
              background-position: center;
              height: 25px !important; 
              width: 26px !important; 
              margin-top: 0 !important;                    
              background-color: #000;       
              background-size: 20px;                  
              transition: $transition1;    
            }    
             &:hover{                  
                &:after{
                    margin-top: 0 !important; 
                    transition: $transition1;   
                } 
            }
         }        
     }
  }
}
.video_four-article{   
    .boxes{
      .box_style {  
        .thumb-holder {               
             height: 158px !important;                     
           
      }  
    }  
   }
}

.video{ 
    .container-right-side{
        padding-left: 0px; 
        width: calc(75% - 10px);  
      .content{
      .main-title 
        h1{
         font-size: 51px !important;
           }
        } 
    } 
} 
.video_blic {  
.container-left-side{
          width: calc(25% + 10px); 
         }   
      }  
 

 
 .video{
    .boxes{
        .box_style{ 
            .article_box_content{
                h1{
                    font-size: 21px;
                    font-weight: 700; 
                }
            }
        }
    }
 } 
 .video_four-article{
    .boxes{
        .box_style{ 
            .article_box_content{
                h1{
                    font-size: 21px;
                    font-weight: 700; 
                }
            }
        }
    }  
 }   
    



.single-thumb {
    position: relative;
    .single-thumb-play {
        background: url(../images/triangle.png) no-repeat 50%;
        float: left; 
        width: 100px;
        height: 100px;
        background-color: hsla(0,0%,100%,.1);
        border-radius: 50%; 
        position: absolute;  
        top: 50%; 
        left: 50%;
        transform: translate(-50%,-50%);      
    }  
}


.main_video_content{ 
    height: 540px;  
    .main_video_category{
        img{
            height: 100%;  
        }

    } 
}    

